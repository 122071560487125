@import "../../../styles/utilities/index";

$boxShadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
$colorGreyLight: #828282;
$colorOrangeLight: #ee802f;
$gradient: linear-gradient(
  0deg,
  rgba(255, 138, 52, 0.05),
  rgba(255, 138, 52, 0.05)
);

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  // &.empty {}
  @media (max-width: $m) {
    font-size: 1.5rem;
  }

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.player {
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  padding: 40px 30px 30px;
  border-radius: 10px;
  position: relative;
}

.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;

  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 600px;

  font-size: 28px;
  line-height: 32px;

  @media (max-width: $m) {
    font-size: 18px !important;
    line-height: 20px;
  }

  @media (max-width: 451px) {
    max-width: 320px;
  }

  @media (max-width: 400px) {
    font-size: 16px !important;
  }

  @media (max-width: 352px) {
    font-size: 14px !important;
    line-height: 20px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  font-size: 20px;
  line-height: 24px;

  gap: 24px;

  @media (max-width: $m) {
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }
}

.navigationGuest {
  display: flex;
  flex-direction: column;
  gap: 5px;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  flex-wrap: wrap;
  font-size: 20px;
  line-height: 24px;

  .spanBreak {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .guestButton {
    margin: 0px;
    border-style: solid;
    border-color: transparent;
    background-color: $colorOrange;
    color: $colorWhite;

    border-width: 1px;
    border-radius: 20px;
    padding: 3px 19px;
    min-height: 32px;

    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    @media (min-width: $m) {
      &:not(:disabled):hover {
        box-shadow: $boxShadow;
        background-color: $colorOrangeLight;
      }
    }

    &:focus {
      background-color: $colorOrangeDark;
    }

    &:disabled {
      background-color: $colorGreyLight;
    }
  }

  @media (max-width: $m) {
    margin-top: 16px;
    font-size: 16px;
    line-height: 18px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 30px;
}

.discountText {
  color: $colorOrange;
  font-size: 16px;

  margin: 24px 0 0;
  line-height: 20px;

  @media (max-width: $m) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }
}

@media (max-width: 450px) {
  .text {
    font-size: 14px;
  }
}
